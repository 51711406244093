var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      !_vm.redactShow
        ? _c("div", { staticClass: "user_data" }, [
            _c("div", { staticClass: "avatars" }, [
              _c("img", { attrs: { src: _vm.imageUrl, alt: "" } }),
            ]),
            _c("div", { staticClass: "name" }, [
              _c("h2", [
                _c("div", [
                  _vm._v(
                    "\n          " + _vm._s(_vm.userData.name) + "\n        "
                  ),
                ]),
              ]),
              _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.userData.signature
                        ? _vm.userData.signature
                        : _vm.$t("signatureUnbound")
                    ) +
                    "\n      "
                ),
              ]),
            ]),
            _c("div", { staticClass: "wallet-info" }, [
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "label" }, [_vm._v("MetaID:")]),
                _c(
                  "div",
                  {
                    staticClass: "value",
                    attrs: { title: _vm.userData.metaId },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.userData.metaId) +
                        "\n        "
                    ),
                  ]
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-copy",
                    attrs: { title: _vm.$t("copyMetaId") },
                    on: {
                      click: function ($event) {
                        return _vm.handleCopy("MetaID", _vm.userData.metaId)
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "bi bi-clipboard",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "16",
                          height: "16",
                          fill: "currentColor",
                          viewBox: "0 0 16 16",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z",
                          },
                        }),
                        _c("path", {
                          attrs: {
                            d: "M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "label" }, [_vm._v("xPub:")]),
                _c(
                  "div",
                  { staticClass: "value", attrs: { title: _vm.xpubKey } },
                  [_vm._v(_vm._s(_vm.xpubKey))]
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-copy",
                    attrs: { title: _vm.$t("copyXpub") },
                    on: {
                      click: function ($event) {
                        return _vm.handleCopy("xpub", _vm.xpubKey)
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "bi bi-clipboard",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "16",
                          height: "16",
                          fill: "currentColor",
                          viewBox: "0 0 16 16",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z",
                          },
                        }),
                        _c("path", {
                          attrs: {
                            d: "M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "user_type" }, [
              _c("h2", [
                _vm._v("\n        " + _vm._s(_vm.$t("userData")) + "\n      "),
              ]),
              _c("div", [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/per_icon_phone@2x.png"),
                    alt: "",
                  },
                }),
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.userData.phone
                          ? _vm.userData.phone
                          : _vm.$t("phoneUnbound")
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("div", [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/per_icon_email@2x.png"),
                    alt: "",
                  },
                }),
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.userData.email
                          ? _vm.userData.email
                          : _vm.$t("emailUnbound")
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "user_type" }, [
              _c("h2", [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("decuritySettings")) + "\n      "
                ),
              ]),
              _c("div", [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/per_icon_backup@2x.png"),
                    alt: "",
                  },
                }),
                _c(
                  "span",
                  {
                    staticClass: "backups",
                    on: {
                      click: function ($event) {
                        _vm.mnemonicDialog = true
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("walletBackup")) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
            ]),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    _vm.redactShow = true
                    _vm.getUserData()
                  },
                },
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("editingMaterials")) + "\n    "
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm.redactShow
        ? _c("div", { staticClass: "user_data" }, [
            _c(
              "div",
              {
                staticClass: "avatars",
                on: {
                  click: function ($event) {
                    _vm.dialogShow = true
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "avatar",
                  attrs: { src: _vm.imageUrl },
                }),
                _c("s", { staticClass: "change_head" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("clickUpload")) + "\n      "
                  ),
                ]),
                _c("Dialog", {
                  attrs: {
                    customTxAvatar: _vm.customTxAvatar,
                    nftAvatar: _vm.nftAvatar,
                    selectedNftInfo: _vm.selectedNftInfo,
                    imageUrl: _vm.imageUrl,
                    uploadFile: _vm.uploadFile,
                    customAvatar: _vm.customAvatar,
                    mataImg: _vm.mataImg,
                    dialogShow: _vm.dialogShow,
                    option: _vm.option.img,
                    dialogVisible: _vm.dialogVisible,
                  },
                  on: {
                    "update:customTxAvatar": function ($event) {
                      _vm.customTxAvatar = $event
                    },
                    "update:custom-tx-avatar": function ($event) {
                      _vm.customTxAvatar = $event
                    },
                    "update:nftAvatar": function ($event) {
                      _vm.nftAvatar = $event
                    },
                    "update:nft-avatar": function ($event) {
                      _vm.nftAvatar = $event
                    },
                    "update:selectedNftInfo": function ($event) {
                      _vm.selectedNftInfo = $event
                    },
                    "update:selected-nft-info": function ($event) {
                      _vm.selectedNftInfo = $event
                    },
                    "update:imageUrl": function ($event) {
                      _vm.imageUrl = $event
                    },
                    "update:image-url": function ($event) {
                      _vm.imageUrl = $event
                    },
                    "update:uploadFile": function ($event) {
                      _vm.uploadFile = $event
                    },
                    "update:upload-file": function ($event) {
                      _vm.uploadFile = $event
                    },
                    "update:customAvatar": function ($event) {
                      _vm.customAvatar = $event
                    },
                    "update:custom-avatar": function ($event) {
                      _vm.customAvatar = $event
                    },
                    "update:mataImg": function ($event) {
                      _vm.mataImg = $event
                    },
                    "update:mata-img": function ($event) {
                      _vm.mataImg = $event
                    },
                    "update:dialogShow": function ($event) {
                      _vm.dialogShow = $event
                    },
                    "update:dialog-show": function ($event) {
                      _vm.dialogShow = $event
                    },
                    "update:option": function ($event) {
                      return _vm.$set(_vm.option, "img", $event)
                    },
                    "update:dialogVisible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                    "update:dialog-visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "change_box" }, [
              _c("span", [
                _vm._v("\n        " + _vm._s(_vm.$t("userName")) + "\n      "),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inpName,
                    expression: "inpName",
                  },
                ],
                ref: "setname1",
                attrs: { type: "text", placeholder: _vm.userData.name },
                domProps: { value: _vm.inpName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.inpName = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "change_box change_textarea" }, [
              _c("span", [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("userSignature")) + "\n      "
                ),
              ]),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.redactData.signature,
                    expression: "redactData.signature",
                  },
                ],
                attrs: { placeholder: _vm.redactData.signature },
                domProps: { value: _vm.redactData.signature },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.redactData, "signature", $event.target.value)
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "change_box phone" }, [
              _c("span", [
                _vm._v("\n        " + _vm._s(_vm.$t("phone")) + "\n      "),
              ]),
              _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.redactData.phone
                        ? _vm.redactData.phone
                        : _vm.$t("unbound")
                    ) +
                    "\n      "
                ),
              ]),
              _c(
                "p",
                {
                  class: _vm.redactData.phone ? "" : "blue",
                  on: {
                    click: function ($event) {
                      return _vm.binding("phone")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.redactData.phone
                          ? _vm.$t("bound")
                          : _vm.$t("binding")
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "change_box email" }, [
              _c("span", [_vm._v(" " + _vm._s(_vm.$t("email")) + " ")]),
              _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.redactData.email
                        ? _vm.redactData.email
                        : _vm.$t("unbound")
                    ) +
                    "\n      "
                ),
              ]),
              _c(
                "p",
                {
                  class: _vm.redactData.email ? "" : "blue",
                  on: {
                    click: function ($event) {
                      return _vm.binding("email")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.redactData.email
                          ? _vm.$t("bound")
                          : _vm.$t("binding")
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ]),
            _vm._m(0),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                on: {
                  click: function ($event) {
                    return _vm.redactUserData()
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("save")) + "\n    ")]
            ),
            _c("p", [
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      _vm.redactShow = false
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("cancel")) + "\n      ")]
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "up_img",
          attrs: {
            title: _vm.$t("shearImg"),
            visible: _vm.dialogVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "cropper-content" }, [
            _c(
              "div",
              {
                staticClass: "cropper",
                staticStyle: { "text-align": "center" },
              },
              [
                _c("vueCropper", {
                  ref: "cropper",
                  attrs: {
                    img: _vm.option.img,
                    outputSize: _vm.option.size,
                    outputType: _vm.option.outputType,
                    info: true,
                    full: _vm.option.full,
                    canMove: _vm.option.canMove,
                    canMoveBox: _vm.option.canMoveBox,
                    original: _vm.option.original,
                    autoCrop: _vm.option.autoCrop,
                    fixed: _vm.option.fixed,
                    fixedNumber: _vm.option.fixedNumber,
                    centerBox: _vm.option.centerBox,
                    infoTrue: _vm.option.infoTrue,
                    fixedBox: _vm.option.fixedBox,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                      _vm.loading = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.finish },
                },
                [_vm._v(_vm._s(_vm.$t("affirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "mnemonic_dialog",
          attrs: { visible: _vm.mnemonicDialog },
          on: {
            "update:visible": function ($event) {
              _vm.mnemonicDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "mnemonic_box" }, [
            _c("h2", [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("generateMnemonics")) + "\n      "
              ),
            ]),
            _c("p", [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("mnemonicsTips1")) + "\n      "
              ),
            ]),
            _c("p", [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("mnemonicsTips2")) + "\n      "
              ),
            ]),
            _c("div", { staticClass: "df" }, [
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      _vm.mnemonicDialog = false
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.$t("cancel")) + "\n        "
                  ),
                ]
              ),
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      _vm.getMnemonic()
                      _vm.mnemonicShow = true
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.$t("generate")) + "\n        "
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "mnemonic_dialog",
          attrs: { visible: _vm.mnemonicShow },
          on: {
            "update:visible": function ($event) {
              _vm.mnemonicShow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "mnemonic_box",
              class: _vm.mnemonicType ? "mnemonic_box1" : "",
            },
            [
              !_vm.mnemonicType
                ? _c("div", [
                    _c("h2", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("mnemonicsTips3")) +
                          "\n        "
                      ),
                    ]),
                    _c("h2", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("mnemonicsTips4")) +
                          "\n        "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mnemonic" },
                      _vm._l(_vm.mnemonic.mnemonic, function (item, index) {
                        return _c("span", { key: index }, [
                          _vm._v(
                            "\n            " + _vm._s(item) + "\n          "
                          ),
                        ])
                      }),
                      0
                    ),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("route")) + ": " + _vm._s(_vm.path)),
                    ]),
                    _c("div", { staticClass: "df" }, [
                      _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.mnemonicShow = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("mnemonicsTips5")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.NoMnemonicShow()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("mnemonicsTips6")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.mnemonicType
                ? _c("div", [
                    _c("h2", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("mnemonicsTips7")) +
                          "\n        "
                      ),
                    ]),
                    _c(
                      "button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.mnemonicShow = false
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("affirm")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "mnemonic_dialog bingding_dialong",
          attrs: { visible: _vm.bindingShow },
          on: {
            "update:visible": function ($event) {
              _vm.bindingShow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "bingding_box",
              on: {
                click: function ($event) {
                  _vm.selectShow = false
                },
              },
            },
            [
              _c("h2", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.bindingType === "email"
                        ? _vm.$t("bindMailbox")
                        : _vm.$t("bindingMobile")
                    ) +
                    "\n      "
                ),
              ]),
              _vm.bindingType === "phone"
                ? _c(
                    "div",
                    { staticClass: "countries" },
                    [
                      _c(
                        "vue-country-intl",
                        {
                          attrs: {
                            schema: "popover",
                            "search-input-placeholder": "",
                          },
                          on: { onChange: _vm.handleCountryChange },
                          model: {
                            value: _vm.phoneCode.dialCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.phoneCode, "dialCode", $$v)
                            },
                            expression: "phoneCode.dialCode",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "phoneCode",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("selectCountry")) +
                                    "\n              : +" +
                                    _vm._s(_vm.phoneCode.dialCode) +
                                    "\n              "
                                ),
                                _c("span", {
                                  class: "iti-flag " + _vm.phoneCode.code,
                                }),
                              ]),
                              _c("i", { staticClass: "iconfont icon-xia" }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "input_box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.bindingAddress,
                      expression: "bindingAddress",
                    },
                  ],
                  attrs: {
                    type: "text",
                    placeholder:
                      _vm.bindingType === "email"
                        ? _vm.$t("bindingEmail")
                        : _vm.$t("bindingPhone"),
                  },
                  domProps: { value: _vm.bindingAddress },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.bindingAddress = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "input_box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.bindingYZM,
                      expression: "bindingYZM",
                    },
                  ],
                  staticClass: "yzm_input",
                  attrs: { type: "text", maxlength: "6" },
                  domProps: { value: _vm.bindingYZM },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.bindingYZM = $event.target.value
                    },
                  },
                }),
                _c(
                  "p",
                  {
                    class: _vm.bindingAddress ? "yellow" : "",
                    on: {
                      click: function ($event) {
                        return _vm.getYzm()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(!_vm.YZMBtn ? _vm.$t("codeTips") : _vm.yzmText) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
              _vm.yzmErr
                ? _c("p", [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("yzmError")) + "\n      "
                    ),
                  ])
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "cursor-p",
                  class: _vm.bindingAddress && _vm.bindingYZM ? "yellow" : "",
                  attrs: { disabled: _vm.bindingBtn },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.setbinding()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("finishBinding")) + "\n      "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "meta_img_box",
          attrs: { visible: _vm.mataImg },
          on: {
            "update:visible": function ($event) {
              _vm.mataImg = $event
            },
          },
        },
        [
          _c("div", [_vm._v("\n      " + _vm._s(_vm.$t("AddImg")) + "\n    ")]),
          _c(
            "div",
            { staticClass: "meta_img_body" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.mataImg = false
                      _vm.loading = false
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("cancel")) + "\n      ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.confirmLoading,
                    disabled: _vm.confirmLoading,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.txIdUplaod
                        ? _vm.handleNFTAvatar(
                            _vm.customTxAvatar
                              ? _vm.customTxAvatar
                              : _vm.selectedNftInfo
                          )
                        : _vm.handleFileChange(_vm.metaImgData)
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("affirm")) + "\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("img", { attrs: { src: "", alt: "", id: "img" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "name" }, [_c("h2")])
  },
]
render._withStripped = true

export { render, staticRenderFns }