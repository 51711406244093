var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user_index" },
    [
      _c(
        "el-row",
        { staticClass: "index", attrs: { type: "flex" } },
        [
          _c(
            "el-col",
            { staticClass: "user_data", attrs: { xs: 24, sm: 8 } },
            [_c("userData")],
            1
          ),
          _c(
            "el-col",
            { staticClass: "user_wallet", attrs: { xs: 24, sm: 16 } },
            [_c("userWallet")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }