<template>
  <div class="user">
    <div class="user_data" v-if="!redactShow">
      <div class="avatars">
        <img :src="imageUrl" alt="" />
      </div>
      <div class="name">
        <h2>
          <div>
            {{ userData.name }}
          </div>
        </h2>
        <p>
          {{ userData.signature ? userData.signature : $t("signatureUnbound") }}
        </p>
      </div>
      <div class="wallet-info">
        <div class="item">
          <div class="label">MetaID:</div>
          <div class="value" :title="userData.metaId">
            {{ userData.metaId }}
          </div>
          <a
            class="btn btn-copy"
            @click="handleCopy('MetaID', userData.metaId)"
            :title="$t('copyMetaId')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-clipboard"
              viewBox="0 0 16 16"
            >
              <path
                d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
              />
              <path
                d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
              />
            </svg>
          </a>
        </div>
        <div class="item">
          <div class="label">xPub:</div>
          <div class="value" :title="xpubKey">{{ xpubKey }}</div>
          <a
            class="btn btn-copy"
            @click="handleCopy('xpub', xpubKey)"
            :title="$t('copyXpub')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-clipboard"
              viewBox="0 0 16 16"
            >
              <path
                d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
              />
              <path
                d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
              />
            </svg>
          </a>
        </div>
      </div>
      <div class="user_type">
        <h2>
          <!-- 个人信息 -->
          {{ $t("userData") }}
        </h2>
        <div>
          <img src="../../assets/images/per_icon_phone@2x.png" alt="" />
          <span>
            {{ userData.phone ? userData.phone : $t("phoneUnbound") }}
          </span>
        </div>
        <div>
          <img src="../../assets/images/per_icon_email@2x.png" alt="" />
          <span>
            {{ userData.email ? userData.email : $t("emailUnbound") }}
          </span>
        </div>
      </div>
      <!-- <div class="user_type">
        <h2>
          {{ $t("defaultCard") }}
        </h2>
        <div class="wallet_type">
          <div>
            <img src="../../assets/images/per_icon_wallet@2x.png" alt="" />
            <span>
              {{ userData.card ? userData.card : "BSV" }}
            </span>
          </div>
          <span>
            {{
              this.$store.state.currency ? this.$store.state.currency : "CNY"
            }}
          </span>
        </div>
      </div> -->
      <div class="user_type">
        <h2>
          <!-- 安全设置 -->
          {{ $t("decuritySettings") }}
        </h2>
        <div>
          <img src="../../assets/images/per_icon_backup@2x.png" alt="" />
          <span @click="mnemonicDialog = true" class="backups">
            <!-- 钱包备份 -->
            {{ $t("walletBackup") }}
          </span>
        </div>
        <!-- <div @click="toEditPassword">
          <img src="../../assets/images/per_icon_xiugai@2x.png" alt="" />
          <span @click="mnemonicDialog = true" class="backups">
            {{ $t("editPasswordLabel") }}
          </span>
        </div> -->
      </div>
      <button
        @click="
          redactShow = true;
          getUserData();
        "
      >
        {{ $t("editingMaterials") }}
      </button>
    </div>
    <div class="user_data" v-if="redactShow">
      <!-- 点击上传 -->
      <div class="avatars" @click="dialogShow = true">
        <img :src="imageUrl" class="avatar" />
        <s class="change_head">
          {{ $t("clickUpload") }}
        </s>
        <Dialog
          :customTxAvatar.sync="customTxAvatar"
          :nftAvatar.sync="nftAvatar"
          :selectedNftInfo.sync="selectedNftInfo"
          :imageUrl.sync="imageUrl"
          :uploadFile.sync="uploadFile"
          :customAvatar.sync="customAvatar"
          :mataImg.sync="mataImg"
          :dialogShow.sync="dialogShow"
          :option.sync="option.img"
          :dialogVisible.sync="dialogVisible"
        ></Dialog>
        <!-- <Dialog :imageUrl.sync='imageUrl' :dialogShow.sync="dialogShow" :redactShow.sync='redactShow' :imageTx.sync='imageTx'></Dialog> -->
        <!-- <el-upload
          class="avatar-uploader"
          :before-upload="beforeAvatarUpload"
          :show-file-list="false"
          :auto-upload="false"
          action="#"
          :on-change="changeUpload"
        >
          <img :src="imageUrl" class="avatar" />
          <s class="change_head">
           
            {{ $t("clickUpload") }}
          </s>
        </el-upload> -->
      </div>
      <!-- <div class="avatars">
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeAvatarUpload"
          :show-file-list="false"
          :auto-upload="false"
          action="#"
          :on-change="changeUpload"
        >
          <img :src="imageUrl" class="avatar" />
          <s class="change_head">
           
            {{ $t("clickUpload") }}
          </s>
        </el-upload>
      </div> -->
      <div class="change_box">
        <span>
          <!-- 用户名 -->
          {{ $t("userName") }}
        </span>
        <input
          type="text"
          v-model="inpName"
          ref="setname1"
          :placeholder="userData.name"
        />
      </div>
      <div class="change_box change_textarea">
        <span>
          <!-- 个性签名 -->
          {{ $t("userSignature") }}
        </span>
        <textarea
          v-model="redactData.signature"
          :placeholder="redactData.signature"
        ></textarea>
      </div>
      <div class="change_box phone">
        <span>
          <!-- 手机 -->
          {{ $t("phone") }}
        </span>
        <p>
          {{ redactData.phone ? redactData.phone : $t("unbound") }}
        </p>
        <p @click="binding('phone')" :class="redactData.phone ? '' : 'blue'">
          {{ redactData.phone ? $t("bound") : $t("binding") }}
        </p>
      </div>
      <div class="change_box email">
        <span> {{ $t("email") }} </span>
        <p>
          {{ redactData.email ? redactData.email : $t("unbound") }}
        </p>
        <p :class="redactData.email ? '' : 'blue'" @click="binding('email')">
          {{ redactData.email ? $t("bound") : $t("binding") }}
        </p>
      </div>
      <!-- <div class="change_box">
        <span> -->
      <!-- 默认卡片 -->
      <!-- {{ $t("defaultCard") }}
        </span>
        <el-select class="cardSelect" v-model="redactData.card">
          <el-option
            v-for="item in cardOptions"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </div>
      <div class="change_box">
        <span> -->
      <!-- 币种 -->
      <!-- {{ $t("currency") }}
        </span>
        <el-select class="cardSelect" v-model="redactData.currency">
          <el-option
            v-for="item in currency"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </div> -->
      <div class="name">
        <h2></h2>
        <!-- <p>
          这个人很懒什么都没留下
        </p> -->
      </div>
      <button v-loading="isLoading" @click="redactUserData()">
        <!-- 保存 -->
        {{ $t("save") }}
      </button>
      <p>
        <span @click="redactShow = false">
          <!-- 取消 -->
          {{ $t("cancel") }}
        </span>
      </p>
    </div>
    <!--付费弹窗-->
    <el-dialog
      :title="$t('shearImg')"
      :visible.sync="dialogVisible"
      append-to-body
      class="up_img"
    >
      <div class="cropper-content">
        <div class="cropper" style="text-align: center">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="true"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :centerBox="option.centerBox"
            :infoTrue="option.infoTrue"
            :fixedBox="option.fixedBox"
          ></vueCropper>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false;
            loading = false;
          "
          >{{ $t("cancel") }}</el-button
        >
        <el-button type="primary" @click="finish" :loading="loading">{{
          $t("affirm")
        }}</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="mnemonicDialog" class="mnemonic_dialog">
      <div class="mnemonic_box">
        <h2>
          <!-- 生成助记词 -->
          {{ $t("generateMnemonics") }}
        </h2>
        <p>
          <!-- 请妥善保管好您的助记词，以下单词可以让你在丢失密码时访问到您的资金. -->
          {{ $t("mnemonicsTips1") }}
        </p>
        <p>
          <!-- 请确保周边无人，并用纸记录下来，不要透露给任何人并且妥善保管. -->
          {{ $t("mnemonicsTips2") }}
        </p>
        <div class="df">
          <button @click="mnemonicDialog = false">
            <!-- 取消 -->
            {{ $t("cancel") }}
          </button>
          <button
            @click="
              getMnemonic();
              mnemonicShow = true;
            "
          >
            <!-- 生成 -->
            {{ $t("generate") }}
          </button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="mnemonicShow" class="mnemonic_dialog">
      <div class="mnemonic_box" :class="mnemonicType ? 'mnemonic_box1' : ''">
        <div v-if="!mnemonicType">
          <h2>
            <!-- 请妥善保管以下单词. -->
            {{ $t("mnemonicsTips3") }}
          </h2>
          <h2>
            <!-- 一旦丢失，将不再找回 -->
            {{ $t("mnemonicsTips4") }}
          </h2>
          <div class="mnemonic">
            <span v-for="(item, index) in mnemonic.mnemonic" :key="index">
              {{ item }}
            </span>
          </div>
          <p>{{ $t("route") }}: {{ path }}</p>
          <div class="df">
            <button @click="mnemonicShow = false">
              <!-- 以后保存 -->
              {{ $t("mnemonicsTips5") }}
            </button>
            <button @click="NoMnemonicShow()">
              <!-- 保存好了 -->
              {{ $t("mnemonicsTips6") }}
            </button>
          </div>
        </div>
        <div v-if="mnemonicType">
          <h2>
            <!-- 您已备份过助记词，将不再展示助记词. -->
            {{ $t("mnemonicsTips7") }}
          </h2>
          <button @click="mnemonicShow = false">
            <!-- 确定 -->
            {{ $t("affirm") }}
          </button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="bindingShow"
      class="mnemonic_dialog bingding_dialong"
    >
      <div class="bingding_box" @click="selectShow = false">
        <h2>
          {{
            bindingType === "email" ? $t("bindMailbox") : $t("bindingMobile")
          }}
        </h2>
        <div class="countries" v-if="bindingType === 'phone'">
          <vue-country-intl
            v-model="phoneCode.dialCode"
            schema="popover"
            @onChange="handleCountryChange"
            search-input-placeholder=""
          >
            <div class="phoneCode" slot="reference">
              <div>
                <!-- 选择国家 -->
                {{ $t("selectCountry") }}
                : +{{ phoneCode.dialCode }}
                <span :class="'iti-flag ' + phoneCode.code"> </span>
              </div>
              <i class="iconfont icon-xia"> </i></div
          ></vue-country-intl>
        </div>
        <div class="input_box">
          <input
            type="text"
            v-model="bindingAddress"
            :placeholder="
              bindingType === 'email' ? $t('bindingEmail') : $t('bindingPhone')
            "
          />
        </div>
        <div class="input_box">
          <input
            type="text"
            maxlength="6"
            v-model="bindingYZM"
            class="yzm_input"
          />
          <p @click="getYzm()" :class="bindingAddress ? 'yellow' : ''">
            {{ !YZMBtn ? $t("codeTips") : yzmText }}
          </p>
        </div>
        <p v-if="yzmErr">
          <!-- 验证码错误 -->
          {{ $t("yzmError") }}
        </p>
        <button
          class="cursor-p"
          :class="bindingAddress && bindingYZM ? 'yellow' : ''"
          @click.stop="setbinding()"
          :disabled="bindingBtn"
        >
          <!-- 完成绑定 -->
          {{ $t("finishBinding") }}
        </button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="mataImg" class="meta_img_box">
      <div>
        {{ $t("AddImg") }}
      </div>
      <div class="meta_img_body">
        <el-button
          @click="
            mataImg = false;
            loading = false;
          "
        >
          {{ $t("cancel") }}
        </el-button>
        <el-button
          type="primary"
          :loading="confirmLoading"
          :disabled="confirmLoading"
          @click.stop="
            txIdUplaod
              ? handleNFTAvatar(
                  customTxAvatar ? customTxAvatar : selectedNftInfo
                )
              : handleFileChange(metaImgData)
          "
        >
          {{ $t("affirm") }}
        </el-button>
      </div>
    </el-dialog>
    <img src="" alt="" id="img" />
  </div>
</template>

<script>
// var fs = require('fs')
import {
  gettokenpage,
  bindPhoneOrEmail,
  setuserinfo,
  registerVerification,
  settoken,

  // uploadFileApiForShowMoney,
} from "@/api/api";
import countries from "@/common/js/countryList";
import Dialog from "@/components/dialog.vue";
import { avatarUrl, imageUrl } from "../../common/js/baseApi";

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      selectedNftInfo: {},
      nftAvatar: "",
      customTxAvatar: "",
      uploadFile: "",
      customAvatar: "",
      dialogShow: false,
      userData: "",
      inpName: "",
      isLoading: false,
      imageUrl: avatarUrl() + this.$store.state.userData.metaId,
      dialogVisible: false, // 图片剪切弹窗
      mnemonicDialog: false, // 钱包备份弹窗
      mnemonicShow: false, // 助记词未弹窗
      mnemonicType: false, // 助记词已保存弹窗
      redactShow: false, // 修改用户资料
      bindingShow: false,
      bindingBtn: false,
      confirmLoading: false,
      selectShow: false,
      mataImg: false,
      countries: countries,
      phoneCode: {
        code: "cn",
        name: "China (中国)",
        dialCode: 86,
        phoneFormat: "131 2345 6789",
      },
      bindingType: "",
      bindingAddress: "",
      bindingYZM: "",
      yzmText: this.$t("codeTips"),
      yzmErr: false,
      mnemonic: "",
      path: "",
      imageUrl2: "",
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // autoCropWidth: 300, // 默认生成截图框宽度
        // autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [5, 5], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      redactData: {
        signature: "",
        card: "BSV",
        currency: "CNY",
      },
      currency: ["CNY", "USD"],
      cardOptions: ["BSV"],
      fileinfo: "",
      loading: false,
      timer: "",
      YZMBtn: false,
      metaImgData: "",
      imageTx: "",
    };
  },
  computed: {
    timeText() {
      return this.$t("getCode");
    },
    txIdUplaod() {
      if (this.nftAvatar || this.customTxAvatar) {
        return true;
      } else {
        return false;
      }
    },
    xpubKey() {
      return this.$store.state.metanet._fundingKey.xpubkey || "";
    },
  },
  filters: {
    userHeader(metaId) {
      return `${avatarUrl()}${metaId}`;
    },
  },
  mounted() {},
  methods: {
    handleCountryChange(item) {
      console.log(item);
      this.phoneCode.code = item.iso2;
    },
    toEditPassword() {
      this.$router.push({
        name: "editPassword",
      });
    },
    arrayBufferToBase64(buffer) {
      let img = JSON.parse(buffer);
      var binary = "";
      var bytes = new Uint8Array(img.data);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      let sty = window.btoa(binary);
      let type = "image/jpeg";
      return "data:" + type + ";base64," + sty;
    },
    //NFT头像上链
    async handleNFTAvatar(nftInfo) {
      const protocolData = {
        type: this.customTxAvatar ? "avatar" : nftInfo.avatarType,
        tx: this.customTxAvatar ? nftInfo : nftInfo.avatarTxId,
        codehash: this.customTxAvatar ? "" : nftInfo.codehash,
        genesis: this.customTxAvatar ? "" : nftInfo.genesis,
        tokenIndex: this.customTxAvatar ? "" : nftInfo.tokenIndex,
        updateTime: new Date().getTime(),
        memo: this.customTxAvatar ? "" : nftInfo.desc,
      };
      this.confirmLoading = true;
      this.$utils
        .upNftAvatar(this.$store.state.infoTxId, protocolData)
        .then((res) => {
          console.log("上传头像", res);
          if (res.code === 200) {
            console.log("000000000", res);
            this.imageUrl = this.customTxAvatar
              ? `${imageUrl()}${this.customTxAvatar}`
              : `${imageUrl()}${nftInfo.avatarTxId}`;
            this.$store.state.headUrl = this.customTxAvatar
              ? this.customTxAvatar
              : nftInfo.avatarTxId;
            this.imageTx = this.customTxAvatar
              ? this.customTxAvatar
              : nftInfo.avatarTxId;
            this.redactShow = false;
            this.mataImg = false;
            this.confirmLoading = false;
            this.redactUserData();
          } else {
            this.$message.success(res.data.message);
          }
        })
        .catch((err) => {
          this.mataImg = false;
          this.confirmLoading = false;
        });
    },
    // 头像上链
    async handleFileChange(e) {
      const file = e;
      const fileType = file.type;
      console.log("zxczxczxcz4445545xczxczxc", file, fileType);
      // this.fileType = fileType
      const reader = new FileReader();
      this.confirmLoading = true;
      // let fileBinary
      // const vm = this
      reader.onload = async () => {
        const arrayBuffer = reader.result;
        let buffer, hex;
        // let hex
        if (arrayBuffer) {
          buffer = Buffer.from(arrayBuffer);
          // fileBinary = buffer
          hex = buffer.toString("hex");
        }
        console.log("555sasdas", hex);
        this.$utils
          .UpheadImg(this.$store.state.infoTxId, hex, fileType)
          .then((res) => {
            console.log("上传头像", res);
            if (res.code === 200) {
              this.imageUrl = this.uploadFile;
              this.$store.state.headUrl = this.uploadFile;
              this.imageTx = res.data.txId;
              this.redactShow = false;
              this.mataImg = false;
              this.confirmLoading = false;
              this.redactUserData();
            } else {
              this.$message.success(res.data.message);
            }
          })
          .catch((err) => {
            this.mataImg = false;
            this.confirmLoading = false;
          });
        // this.$nextTick(() => {
        // })
      };
      reader.readAsArrayBuffer(file);
    },
    hexToBase64(str) {
      return btoa(
        String.fromCharCode.apply(
          null,
          str
            .replace(/\r|\n/g, "")
            .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
            .replace(/ +$/, "")
            .split(" ")
        )
      );
    },
    GetPhone(item) {
      this.phoneCode = item;
    },
    setPhoneNub() {
      if (this.bindingType === "email") {
        return this.bindingAddress;
      }
      let bindingAddress = this.bindingAddress;
      if (this.phoneCode.dialCode != 86) {
        bindingAddress = this.phoneCode.dialCode + this.bindingAddress;
      }
      return bindingAddress;
    },
    // 绑定获取验证码
    getYzm() {
      this.YZMBtn = true;
      if (!this.bindingAddress) {
        return this.$message.error(
          this.bindingType === "phone"
            ? this.$t("phoneNull")
            : this.$t("emailNull")
        );
      }
      let params = {
        userType: this.bindingType,
        name: this.userData.name,
      };
      if (this.bindingType === "phone") {
        params.phone =
          (this.phoneCode.dialCode != 86 ? "+" : "") + this.setPhoneNub();
      } else {
        params.email = this.bindingAddress;
      }
      registerVerification(params)
        .then((res) => {
          let nub = 60;
          let that = this;
          that.yzmText = nub + "s";
          clearInterval(this.timer);
          function timeout() {
            // console.log(nub)
            if (nub > 0) {
              that.timer = setTimeout(() => {
                --nub;
                that.yzmText = nub + "s";
                timeout();
              }, 1000);
            } else {
              that.YZMBtn = false;
              that.yzmText = that.timeText;
            }
          }
          timeout();
          this.$message({
            message: this.$t("codeSuccess"),
            type: "success",
          });
        })
        .catch((err) => {
          this.YZMBtn = false;
          if (err?.message === "Account exist") {
            return this.$message.error(
              this.$t("Error") +
                (this.bindingType == "phone"
                  ? this.$t("phonebounded")
                  : this.$t("emailbounded"))
            );
          }
          return this.$message.error(this.$t("Error") + err.message);
        });
      // console.log(params)
    },
    // 完成绑定
    async setbinding() {
      let params;
      if (this.bindingBtn) {
        return this.$message.error(this.$t("discussWarning"));
      }
      this.bindingBtn = true;
      let bindingAddress = this.setPhoneNub();
      if (this.bindingType === "email") {
        params = {
          email: this.bindingAddress,
          phone: this.userData.phone,
          code: this.bindingYZM,
          userType: "phone",
        };
      } else {
        params = {
          email: this.userData.email,
          phone: bindingAddress,
          code: this.bindingYZM,
          userType: "email",
        };
      }
      console.log(this.$store.state.infoTxId, this.bindingType, bindingAddress);
      bindPhoneOrEmail(params)
        .then(async (res) => {
          this.bindingBtn = false;
          if (this.bindingType === "email") {
            this.userData.email = this.bindingAddress;
            this.redactData.email = this.bindingAddress;
          } else {
            this.userData.phone = this.bindingAddress;
            const dialCode =
              this.phoneCode.dialCode != 86 ? this.phoneCode.dialCode : "";
            this.redactData.phone = `${dialCode}${this.bindingAddress}`;
          }
          this.$forceUpdate();
          this.bindingShow = false;
          this.$message({
            message: this.$t("bindingSuccess"),
            type: "success",
          });
          await this.$utils
            .UpLogin(
              this.$store.state.infoTxId,
              this.bindingType,
              bindingAddress
            )
            .then()
            .catch();
        })
        .catch((err) => {
          this.yzmErr = true;
          this.bindingBtn = false;
          if (err.message == "Have not send verification code") {
            return this.$message.error(this.$t("noSended"));
          }
          return this.$message.error(err.message);
        });
      // console.log(params)
    },
    // 编辑用户名
    Getfocus() {
      this.inpName = this.userData.name;
      // console.log(this.$store.state.wallet)
      setTimeout(() => {
        this.$refs.setname1.focus();
      }, 20);
    },
    // 绑定
    binding(type) {
      // console.log(type)
      if (this.redactData[type]) {
        return;
      }
      this.bindingType = type;
      // this.bindingType = 'phone'
      this.bindingShow = true;
      // this.$ShowMoney.noneMnemonic.setLoginType(type)
      this.getUserData();
    },
    // beforeAvatarUpload(file) {
    //   const isLt5M = file.size / 1024 / 1024 < 5;
    //   if (!isLt5M) {
    //     this.$message.error(this.$t("uploadingExplain"));
    //     return false;
    //   }
    //   return isLt5M;
    // },
    // 上传头像
    // changeUpload(file, fileList) {
    //   this.fileinfo = file;
    //   // console.log(file)
    //   var reader = new FileReader();
    //   var _this = this;
    //   reader.onload = (e) => {
    //     let data;
    //     // console.log(e)
    //     if (typeof e.target.result === "object") {
    //       // 把 Array Buffer 转化为 blob 如果是 base64 不需要
    //       data = window.URL.createObjectURL(new Blob([e.target.result]));
    //     } else {
    //       data = e.target.result;
    //     }
    //     // console.log(data)
    //     _this.option.img = data;
    //     this.dialogVisible = true;
    //   };
    //   reader.readAsDataURL(file.raw);
    // },
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          resolve(e.target.result);
        };
        // readAsDataURL
        fileReader.readAsDataURL(blob);
        fileReader.onerror = () => {
          reject(new Error("blobToBase64 error"));
        };
      });
    },
    // // 图片压缩
    serMinImg(imgData) {
      return new Promise((resolve, reject) => {
        let image = new Image();
        image.src = imgData;
        // let MetaBalance = this.$store.state.metanet._balance
        // console.log(MetaBalance)
        image.onload = function () {
          let canvas = document.createElement("canvas");
          let context = canvas.getContext("2d");
          let imageWidth = 150;
          let imageHeight = 150;
          // let data = ''
          canvas.width = imageWidth;
          canvas.height = imageHeight;
          context.drawImage(image, 0, 0, imageWidth, imageHeight);
          canvas.toBlob((blob) => {
            resolve(blob);
          }, "image/jpeg");
        };
      });
    },
    // // 上传头像获取 URL
    finish() {
      let that = this;
      this.loading = true;
      // console.log(this.$refs.cropper.getImgAxis())
      this.$refs.cropper.getCropBlob((data) => {
        // console.log(data)
        that.blobToBase64(data).then((res) => {
          this.uploadFile = res;
          this.customAvatar = res;
          console.log("zxz11213czxczxczxc", res);
          // console.log(res, 'base64')
          // that.imageUrl = res;
          // that.$store.state.headUrl = res
          that.serMinImg(res).then((res) => {
            this.metaImgData = res;
            // this.mataImg = true; //控制付费弹窗
            this.loading = false;
            that.dialogVisible = false;
          });
        });
        let formData = new FormData();
        formData.append("file", data, that.fileinfo.name);
        var fileName = "goods" + this.fileinfo.uid;
        console.log(fileName, data);
      });
    },
    // 获取助记词
    getMnemonic() {
      // setTokenPage 为覆盖式传参，所以要定义好 obj,card 为钱包.mnemonic 为是否保存助记词
      // mnemonic = true 为保存，false 为未保存
      let params = {
        userType:
          this.userData.register ||
          (this.$store.state.userData.phone ? "phone" : "email"),
      };
      params.userType === "phone"
        ? (params.phone = this.userData.phone)
        : (params.email = this.userData.email);
      gettokenpage(params)
        .then((res) => {
          // console.log(res, 'getTokenPage')
          let data = res.tokenString ? JSON.parse(res.tokenString) : {};
          if (!data.mnemonic) {
            // console.log(this.$store.state.wallet.getMnemonic())
            this.mnemonic = this.$store.state.wallet._ppk
              ? this.$store.state.wallet.getMnemonic()
              : {
                  mnemonic: this.$store.state.wallet._mnemonic_str,
                  path: this.$store.state.wallet._path,
                };
            this.mnemonic.mnemonic = this.mnemonic.mnemonic.split(" ");
            this.path = this.$store.state.wallet._path;
          }
          this.mnemonicDialog = false;
          // this.mnemonicType = res.data.mnemonic
        })
        .catch((err) => {
          //TODO:暂改成网络出错也展示助记词
          this.mnemonic = this.$store.state.wallet._ppk
            ? this.$store.state.wallet.getMnemonic()
            : {
                mnemonic: this.$store.state.wallet._mnemonic_str,
                path: this.$store.state.wallet._path,
              };
          this.mnemonic.mnemonic = this.mnemonic.mnemonic.split(" ");
          this.mnemonicDialog = false;
          return this.$message.error(this.$t("Error") + err.message);
        });
    },
    // 以后是否展示助记词
    NoMnemonicShow() {
      // console.log(211)
      this.$utils
        .gettokenpageData()
        .then((res) => {
          // console.log(res)
          let tokenpageData = res || {};
          tokenpageData.mnemonic = false;
          tokenpageData["isSaveMem"] = "saved";
          let params = {
            userType:
              this.userData.register ||
              (this.$store.state.userData.phone ? "phone" : "email"),
            type: 2,
            tokenString: JSON.stringify(tokenpageData),
          };
          params.userType === "phone"
            ? (params.phone = this.userData.phone)
            : (params.email = this.userData.email);
          // params.tokenPage.mnemonic = false
          settoken(params)
            .then(() => {
              this.mnemonicShow = false;
              this.$store.state.mnemonic = true;
              // this.$message({
              //   message: this.$t("submitSuccess"),
              //   type: "success",
              // });
            })
            .catch((err) => {
              this.mnemonicShow = false;
              return this.$message.error(this.$t("Error") + err);
            });
        })
        .catch((err) => {
          this.$message.error(this.$t("Error") + err);
        });

      // console.log(this.$store.state.myTokenList)
      // let wallet = []
      // for (let i = 0; i < this.$store.state.myTokenList.length; i++) {
      //   wallet.push(this.$store.state.myTokenList[i].symbol)
      // }
      // let userData = {
      //   card: wallet,
      //   mnemonic: false
      // }
      // console.log(userData)
    },
    handleCopy(type, message) {
      const notify = [type, this.$t("alreadyCopiedToClipboard")].join(" ");
      this.$copyText(message).then((e) => {
        this.$message.success(notify);
      });
    },
    // 获取用户信息
    getUserData() {
      let params = {
        userType:
          this.userData.register ||
          (this.$store.state.userData.phone ? "phone" : "email"),
      };
      params.userType === "phone"
        ? (params.phone = this.userData.phone)
        : (params.email = this.userData.email);
      // console.log(params)
      // this.$store.state.wallet.getTokenPage().then(res => {
      // console.log(params)
      gettokenpage(params)
        .then((res) => {
          // console.log(res, 123)
          if (res.tokenString) {
            let data = JSON.parse(res.tokenString);
            // console.log(data, '用户数据')
            if (data.card && this.cardOptions.length - 1 !== data.card.length) {
              this.cardOptions = data.card;
              this.cardOptions.unshift("BSV");
            }
            this.userData.card = data.defaultCard ? data.defaultCard : "BSV";
            this.userData.signature = data.signature ? data.signature : "";
            // this.cardOptions = arr.unshift('BSV')
            // console.log(this.cardOptions)
            this.redactData.card = data.defaultCard ? data.defaultCard : "BSV";
            this.redactData.signature = data.signature ? data.signature : "";
          }
          this.redactData.name = this.userData.name ? this.userData.name : "";
          this.redactData.phone = this.userData.phone
            ? this.userData.phone
            : "";
          this.redactData.email = this.userData.email
            ? this.userData.email
            : "";
          this.$forceUpdate();
        })
        .catch((err) => {
          return this.$message.error(this.$t("Error") + err);
        });
    },
    // 设置用户资料
    async redactUserData() {
      this.isLoading = true;
      let params = {
        userType:
          this.userData.register ||
          (this.$store.state.userData.phone ? "phone" : "email"),
      };
      this.userData.register === "phone"
        ? (params.phone = this.userData.phone)
        : (params.email = this.userData.email);
      if (this.inpName) {
        // console.log()
        params.userName = this.inpName;
      }
      if (this.imageUrl2) {
        params.headUrl = imageUrl() + this.imageTx;
      }
      setuserinfo(params).then(async () => {
        // console.log(res)
        if (this.inpName !== this.userData.name && this.inpName !== "") {
          try {
            await this.$utils.UpName(this.inpName);
          } catch (error) {
            this.isLoading = false;
            return;
          }
        }
        if (this.inpName) this.$store.state.userData.name = this.inpName;
        if (
          this.imageUrl &&
          this.imageUrl !==
            "https://showjob.oss-cn-hangzhou.aliyuncs.com/index/img_photo_default.png" &&
          this.imageUrl !== this.userData.headUrl
        ) {
          this.$store.state.userData.headUrl = this.imageUrl;
          this.$store.state.headUrl = this.imageUrl;
          console.log("user_data", this.imageUrl);
          this.userData.headUrl = this.imageUrl;
          // console.log(this.$store.state.userData.headUrl)
        }
        this.redactShow = false;
        this.isLoading = false;
        this.$message({
          message: this.$t("submitSuccess"),
          type: "success",
        });
      });
    },
  },
  watch: {
    "$store.state.userData": {
      handler: function () {
        this.userData = this.$store.state.userData;
        this.$forceUpdate();
      },
      deep: true,
    },
  },
  created() {
    console.log(this);
    // console.log(this.$store.state.metanet)
    if (this.$store.state.userData) {
      // console.log(this.$store.state.wallet.getMnemonic())
      this.userData = JSON.parse(JSON.stringify(this.$store.state.userData));
      // console.log(this.$store.state.wallet.getMnemonic())
      this.getUserData();
      this.redactData.currency = this.$store.state.currency
        ? this.$store.state.currency
        : "CNY";
      // 获取用户数据
      // this.$ShowMoney.noneMnemonic.setLoginType('email')
      // console.log(this.userData, this.$store.state.myTokenList)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/intl.css";
.user {
  max-width: 300px;
  width: 100%;
  background: #ffffff;
  border-radius: 23px;
  margin-top: 85px;
  padding: 25px;
  .avatars {
    margin-top: -79px;
    margin-left: 72px;
    width: 108px;
    height: 108px;
    position: relative;
    background: #ffffff;
    border-radius: 50%;
    padding: 5px;
    z-index: 999;

    .change_head {
      width: 98px;
      display: none;
      height: 98px;
      border-radius: 50%;
      text-align: center;
      line-height: 98px;
      position: absolute;
      background: rgba($color: #303133, $alpha: 0.3);
      left: 5px;
      top: 5px;
      color: #fff;
    }
    &:hover .change_head {
      display: block;
    }
    img {
      height: 98px;
      width: 98px;
      border-radius: 50%;
    }
  }
  .name {
    h2 {
      margin-top: 25px;
      text-align: center;
      font-size: 24px;
    }
    p {
      font-size: 14px;
      color: #909399;
      text-align: center;
    }
    input {
      border: none;
      height: 36px;
      width: 100%;
      font-size: 24px;
      outline: none;
    }
  }
  .user_data > button {
    width: 120px;
    height: 38px;
    background: rgba(234, 179, 0, 1);
    border-radius: 19px;
    font-size: 15px;
    line-height: 38px;
    text-align: center;
    display: block;
    margin: 25px auto;
    cursor: pointer;
    color: #ffffff;
  }
  .user_data > p {
    text-align: center;
    span {
      cursor: pointer;
    }
  }
}
.cursor-p {
  cursor: pointer;
}
.user_type {
  border-top: 1px solid #fcf4d9;
  padding-bottom: 25px;
  h2 {
    font-size: 18px;
    color: #303133;
    margin: 20px 0;
  }
  > div {
    height: 30px;
    display: flex;
    align-items: center;
    img {
      width: 26px;
      height: 26px;
    }
    span {
      margin-left: 10px;
      font-size: 14px;
      color: #606266;
    }
    .backups {
      cursor: pointer;
    }
  }
  .wallet_type {
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
    }
  }
}
.cropper-content {
  .cropper {
    width: auto;
    height: 300px;
  }
}
::v-deep .el-button--primary {
  color: #ffffff;
  background: #eab300;
  border-color: #eab300;
}
::v-deep .el-button:hover {
  color: #606266;
  border-color: #dcdfe6;
  background-color: #fff;
}
::v-deep .el-button--primary:hover {
  color: #fff;
  background: #eab300;
  border-color: #eab300;
}
.mnemonic_dialog {
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog {
    border-radius: 1rem;
    width: 50%;
    max-width: 450px;
    overflow: hidden;
  }
  .mnemonic_box {
    h2 {
      text-align: center;
    }
    p {
      margin-top: 2rem;
      font-size: 16px;
      font-weight: bold;
    }
    .mnemonic {
      margin-top: 2rem;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    span {
      border-radius: 1rem;
      height: 2rem;
      line-height: 2rem;
      // margin-right: 2rem;
      margin-top: 1rem;
      width: 22%;
      text-align: center;
      border: 1px solid #999;
    }
    .df {
      justify-content: space-around;
      margin-top: 25px;
      margin-bottom: 15px;
    }
    button {
      margin: 0;
      width: 120px;
      height: 38px;
      border-radius: 19px;
      text-align: center;
      background: #eab300;
      color: #fff;
      &:nth-child(1) {
        border: 1px solid #999;
        background: #fff;
        color: #606266;
      }
    }
  }
  .mnemonic_box1 {
    padding: 20px 0;
    margin-top: 30px;
    h2 {
      text-align: center;
    }
    button {
      margin: 0 auto;
      width: 150px;
      margin-top: 30px;
    }
  }
}
.change_box {
  border: 1px solid #e7e8e9;
  border-radius: 5px;
  height: 45px;
  position: relative;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  span {
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 0 5px;
    background: #fff;
    cursor: default;
    z-index: 2;
  }
  p {
    color: #bfc2cc;
    cursor: default;
  }
  .blue {
    color: #157bee;
    cursor: pointer;
  }
  ::v-deep .el-input__inner {
    border: none;
    outline: none;
  }
  textarea {
    min-width: 100%;
    max-width: 100%;
  }
  input {
    border: none;
    height: 100%;
    outline: none;
    color: #303133;
    &::placeholder {
      color: #bfc2cc;
    }
    &:disabled {
      background: #fff;
      color: #bfc2cc;
    }
  }
}
.email {
  display: none;
}

.phone {
  display: none;
}

::v-deep .vue-country-intl .country-intl-input-wrap {
  border: unset;
}
::v-deep .vue-country-intl .country-intl-label {
  padding-left: 0;
  padding: 7px 16px;
  font-size: 14px;
}
.change_textarea {
  padding: 10px;
  height: unset;
  min-height: 45px;
}
.cardSelect {
  z-index: 1;
  width: 100%;
  display: block;
}
.bingding_box {
  padding: 0 30px;
  max-width: 300px;
  .countries {
    height: 40px;
    font-size: 15px;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #bfc2cc;
    .phoneCode {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .select_phone {
      position: absolute;
      height: 300px;
      left: 0;
      top: 95%;
      z-index: 1;
      background: #fff;
      width: 100%;
      overflow: auto;
      padding: 0 20px;
      border-radius: 5px;
      border: 1px solid #bfc2cc;
    }
    .select_phone::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 10px;
    }
    .select_phone::-webkit-scrollbar-track {
      box-shadow: none;
      /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.1);
    }
    .select_phone::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
      background-color: #aaa;
    }
    .item_phone {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 40px;
      line-height: 40px;
      .item_name {
        width: 60%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  h2 {
    margin-bottom: 20px;
  }
  .input_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    border-bottom: 1px solid #e7e8e9;
    input {
      border: none;
      height: 100%;
      flex-shrink: 1;
      width: 100%;
      font-size: 14px;
      outline: none;
      color: #303133;
      &::placeholder {
        color: #bfc2cc;
      }
    }
    .yzm_input {
      width: calc(100% - 90px);
    }
    p {
      flex-shrink: 0;
      background: #bfc2cc;
      height: 40px;
      min-width: 90px;
      border-radius: 5px;
      color: #fff;
      line-height: 40px;
      text-align: center;
    }
    .yellow {
      background: #eab300;
    }
  }
  > p {
    margin-top: 10px;
    color: #f56c6c;
  }
  > button {
    width: 100%;
    margin-top: 40px;
    line-height: 60px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    height: 60px;
    background: rgba(191, 194, 204, 1);
    border-radius: 9px;
  }
  .yellow {
    background: #eab300;
  }
}
.bingding_dialong {
  ::v-deep .el-dialog {
    max-width: 350px;
  }
}
.meta_img_box {
  top: calc(50vh - 25vh);
  ::v-deep .el-dialog {
    border-radius: 15px;
    max-width: 300px;
  }
  .meta_img_body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .user {
    margin: 0 auto;
  }
  ::v-deep .el-dialog {
    width: 90%;
    margin: 0 auto;
  }
  .mnemonic_dialog {
    .df {
      display: block;
      button {
        width: 100%;
        margin-top: 10px;
      }
    }
    ::v-deep .el-dialog {
      width: 80%;
    }
  }
}

.wallet-info {
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  padding: 12px 0;
  color: #888;
  .item {
    width: 250px;
    display: flex;
    margin-bottom: 5px;
    .label {
      color: #666;
      flex: 0 0 42px;
      margin-right: 6px;
    }
    .value {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
      margin-right: 5px;
    }
    .btn-copy {
      width: 16px;
      height: 16px;
      cursor: pointer;
      color: #999;
      &:hover {
        color: #eab300;
      }
    }
  }
}
</style>
