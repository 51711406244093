var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wallet" },
    [
      _c("div", { staticClass: "wallet_top" }, [
        _c("div", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/per_icon_wallet@2x(1).png"),
              alt: "",
            },
          }),
          _c("span", [
            _vm._v("\n        " + _vm._s(_vm.$t("myWallet")) + "\n      "),
          ]),
        ]),
        _c(
          "span",
          {
            on: {
              click: function ($event) {
                _vm.delShow = !_vm.delShow
              },
            },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.delShow ? _vm.$t("Finished") : _vm.$t("Management")
                ) +
                "\n    "
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "wallet_data" },
        [
          _c("div", { staticClass: "wallet_show" }, [
            _c("div", { staticClass: "wallet_text" }, [
              _c("div", [
                _c("p", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$store.state.balance.bsv) +
                      "\n            " +
                      _vm._s(
                        _vm.$store.state.chain === "BSV" ? "BSV" : "SPACE"
                      ) +
                      "\n          "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "currency" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$store.state.chain === "BSV" ? "BSV" : "MVC")
                  ),
                ]),
                _c("div", [
                  !_vm.BSVnameShow
                    ? _c("i", {
                        staticClass: "iconfont icon-bianji",
                        on: {
                          click: function ($event) {
                            return _vm.inputShow("BSV")
                          },
                        },
                      })
                    : _vm._e(),
                  _c("input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.BSVnameShow,
                        expression: "BSVnameShow",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.BSVname,
                        expression: "BSVname",
                      },
                    ],
                    ref: "BSV",
                    attrs: { type: "text" },
                    domProps: { value: _vm.BSVname },
                    on: {
                      blur: function ($event) {
                        return _vm.setCurrencyName("BSV")
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.BSVname = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _vm._l(_vm.myTokenList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "wallet_show",
                class: item.symbol ? item.symbol : "",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.selectWallet(item)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "wallet_text",
                    style: {
                      background:
                        "url(https://showjob.oss-cn-hangzhou.aliyuncs.com/showMoney/ic_" +
                        item.symbol +
                        "%402x.png) no-repeat",
                      backgroundSize: "70px 72px",
                    },
                  },
                  [
                    _vm.amountShow
                      ? _c("div", [
                          _c("p", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.cutNumberNoZero(
                                    item.balance.amount / 100000000,
                                    9
                                  )
                                ) +
                                "\n            " +
                                _vm._s(item.symbol) +
                                "\n          "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "currency",
                        class: item.delShow ? "show" : "",
                      },
                      [
                        _c("span", [_vm._v(_vm._s(item.symbol))]),
                        _c("div", [
                          !item.nameShow
                            ? _c("span", [_vm._v(_vm._s(item.cardName))])
                            : _vm._e(),
                          !item.nameShow
                            ? _c("i", {
                                staticClass: "iconfont icon-bianji",
                                on: {
                                  click: function ($event) {
                                    return _vm.inputShow(item)
                                  },
                                },
                              })
                            : _vm._e(),
                          _c("input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.nameShow,
                                expression: "item.nameShow",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.cardName,
                                expression: "item.cardName",
                              },
                            ],
                            ref: item.symbol,
                            refInFor: true,
                            attrs: { type: "text" },
                            domProps: { value: item.cardName },
                            on: {
                              blur: function ($event) {
                                return _vm.setCurrencyName(item)
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(item, "cardName", $event.target.value)
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.delShow,
                        expression: "delShow",
                      },
                    ],
                    staticClass: "del_wallet",
                  },
                  [_c("span", { class: item.delShow ? "show" : "" })]
                ),
              ]
            )
          }),
        ],
        2
      ),
      _vm.delShow
        ? _c("div", { staticClass: "delType" }, [
            _c(
              "button",
              {
                class: _vm.delButton ? "delButton" : "",
                attrs: { disabled: !_vm.delButton || !_vm.delShow },
                on: {
                  click: function ($event) {
                    _vm.delDialog = true
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("delete")) + "\n    ")]
            ),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "noK",
          staticStyle: { "margin-top": "15vh" },
          attrs: { title: "", visible: _vm.success, center: "" },
          on: {
            "update:visible": function ($event) {
              _vm.success = $event
            },
          },
        },
        [
          _vm.addList.length > 0
            ? _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.wallet_name,
                        callback: function ($$v) {
                          _vm.wallet_name = $$v
                        },
                        expression: "wallet_name",
                      },
                    },
                    _vm._l(_vm.addList, function (item, index) {
                      return _c(
                        "el-radio",
                        {
                          key: index,
                          staticClass: "radio_type",
                          attrs: { label: item },
                          on: {
                            change: function ($event) {
                              _vm.walletRadio = item
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                "https://showjob.oss-cn-hangzhou.aliyuncs.com/showMoney/list_logo_" +
                                item +
                                "%402x.png",
                              alt: "",
                            },
                          }),
                          _vm._v("\n          " + _vm._s(item) + "\n        "),
                        ]
                      )
                    }),
                    1
                  ),
                  _c(
                    "button",
                    {
                      attrs: { disabled: _vm.addWalletDisabled },
                      on: {
                        click: function ($event) {
                          return _vm.addWallet()
                        },
                      },
                    },
                    [_vm._v("\n        " + _vm._s(_vm.$t("add")) + "\n      ")]
                  ),
                ],
                1
              )
            : _c("div", { staticStyle: { "text-align": "center" } }, [
                _vm._v("\n      " + _vm._s(_vm.$t("NoCardAdd")) + "\n    "),
              ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "centerDialogVisible",
          staticStyle: { "margin-top": "15vh" },
          attrs: {
            title: _vm.$t("delCard"),
            visible: _vm.delDialog,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.delDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "payShow" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("delCardTips")) + "\n    "),
          ]),
          _c("div", { staticClass: "queding" }, [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    _vm.delDialog = false
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("cancel")) + "\n      ")]
            ),
            _c(
              "button",
              {
                staticClass: "pay",
                attrs: { disabled: _vm.delWalletDisabled },
                on: {
                  click: function ($event) {
                    return _vm.delWallet()
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("affirm")) + "\n      ")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }