<template>
  <div class="user_index">
    <el-row type="flex" class="index" >
      <el-col :xs="24" :sm="8" class="user_data">
        <userData></userData>
      </el-col>
      <el-col :xs="24" :sm="16" class="user_wallet">
        <userWallet></userWallet>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import userData from './user_data'
import userWallet from './user_wallet'
export default {
  components: {
    userData,
    userWallet
  }
}
</script>

<style lang="scss" scoped>
.user_index {
  background: url('../../assets/images/emb_line.png') no-repeat;
  background-color: #303133;
  background-position: 0 200px;
  width: 100vw;
  background-size: contain;
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 68px);
}
.index {
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
  padding-top: 45px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
  @media only screen and (max-width: 768px) {
    .user_data, .user_wallet {
      padding: 10px;
    }
    .index {
      padding: 10px;
      padding-top: 70px;
    }
  }
</style>
