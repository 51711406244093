<template>
  <div class="wallet">
    <div class="wallet_top">
      <div>
        <img src="../../assets/images/per_icon_wallet@2x(1).png" alt="" />
        <span>
          <!-- 我的钱包 -->
          {{ $t("myWallet") }}
        </span>
      </div>
      <span @click="delShow = !delShow">
        {{ delShow ? $t("Finished") : $t("Management") }}
      </span>
    </div>
    <div class="wallet_data">
      <div class="wallet_show">
        <div class="wallet_text">
          <div>
            <!-- <h4>
              {{
                $utils.getAmount(
                  $store.state.balance.bsv,
                  $utils.getCurrency() == "CNY" ? 2 : 4
                )
              }}
              {{ $utils.getCurrency() }}
            </h4> -->
            <p>
              {{ $store.state.balance.bsv }}
              {{ $store.state.chain === "BSV" ? "BSV" : "SPACE" }}
            </p>
          </div>
          <div class="currency">
            <span>{{ $store.state.chain === "BSV" ? "BSV" : "MVC" }}</span>
            <div>
              <!-- <span v-if="!BSVnameShow">{{ BSVname }}</span> -->
              <i
                v-if="!BSVnameShow"
                class="iconfont icon-bianji"
                @click="inputShow('BSV')"
              ></i>
              <input
                type="text"
                :ref="'BSV'"
                v-show="BSVnameShow"
                v-model="BSVname"
                @blur="setCurrencyName('BSV')"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="wallet_show"
        v-for="(item, index) in myTokenList"
        :key="index"
        :class="item.symbol ? item.symbol : ''"
        @click.stop="selectWallet(item)"
      >
        <div
          class="wallet_text"
          :style="{
            background:
              'url(https://showjob.oss-cn-hangzhou.aliyuncs.com/showMoney/ic_' +
              item.symbol +
              '%402x.png) no-repeat',
            backgroundSize: '70px 72px',
          }"
        >
          <div v-if="amountShow">
            <!-- <h4>
              {{
                $utils.getAmount(
                  (item.balance.amount / 100000000) * Number(item.toBSV),
                  4
                )
              }}
              {{ $utils.getCurrency() }}
            </h4> -->
            <p>
              {{ cutNumberNoZero(item.balance.amount / 100000000, 9) }}
              {{ item.symbol }}
            </p>
          </div>
          <div class="currency" :class="item.delShow ? 'show' : ''">
            <span>{{ item.symbol }}</span>
            <div>
              <span v-if="!item.nameShow">{{ item.cardName }}</span>
              <i
                v-if="!item.nameShow"
                class="iconfont icon-bianji"
                @click="inputShow(item)"
              ></i>
              <input
                type="text"
                :ref="item.symbol"
                v-show="item.nameShow"
                @blur="setCurrencyName(item)"
                v-model="item.cardName"
              />
            </div>
          </div>
        </div>
        <div class="del_wallet" v-show="delShow">
          <span :class="item.delShow ? 'show' : ''"></span>
        </div>
      </div>
      <!-- 添加卡片 -->
      <!-- <div
        v-if="!delShow"
        class="wallet_add"
        @click="
          getWalletList();
          success = true;
        "
      >
        <img src="../../assets/images/wallet_icon_add@2x.png" alt="" />
        <span>
         
          {{ $t("addCard") }}
        </span>
      </div> -->
    </div>
    <div class="delType" v-if="delShow">
      <button
        :class="delButton ? 'delButton' : ''"
        :disabled="!delButton || !delShow"
        @click="delDialog = true"
      >
        <!-- 删除 -->
        {{ $t("delete") }}
      </button>
    </div>
    <el-dialog
      title=""
      style="margin-top: 15vh"
      :visible.sync="success"
      class="noK"
      center
    >
      <div v-if="addList.length > 0">
        <el-radio-group v-model="wallet_name">
          <el-radio
            :label="item"
            class="radio_type"
            v-for="(item, index) in addList"
            :key="index"
            @change="walletRadio = item"
          >
            <img
              :src="
                'https://showjob.oss-cn-hangzhou.aliyuncs.com/showMoney/list_logo_' +
                item +
                '%402x.png'
              "
              alt=""
            />
            {{ item }}
          </el-radio>
        </el-radio-group>
        <button :disabled="addWalletDisabled" @click="addWallet()">
          <!-- 添 加 -->
          {{ $t("add") }}
        </button>
      </div>
      <div style="text-align: center" v-else>
        <!-- 暂无可添加的卡片 -->
        {{ $t("NoCardAdd") }}
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('delCard')"
      style="margin-top: 15vh"
      :visible.sync="delDialog"
      class="centerDialogVisible"
      center
    >
      <div class="payShow">
        <!-- 是否确认删除以选中的钱包 -->
        {{ $t("delCardTips") }}
      </div>
      <div class="queding">
        <button @click="delDialog = false">
          <!-- 取消 -->
          {{ $t("cancel") }}
        </button>
        <button class="pay" @click="delWallet()" :disabled="delWalletDisabled">
          <!-- 确定 -->
          {{ $t("affirm") }}
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { settoken } from "@/api/api";
export default {
  data() {
    return {
      rate: 0,
      tokenizedList: "",
      wallet_name: "",
      success: false,
      walletRadio: "",
      walletToken: [],
      myTokenList: "",
      amountShow: false,
      addList: [],
      delShow: false,
      delButton: false,
      delTokenList: [],
      delDialog: false,
      addWalletDisabled: false,
      delWalletDisabled: false,
      BSVname: "MVC #1",
      BSVnameShow: false,
    };
  },
  methods: {
    // 修改卡片名字
    inputShow(item) {
      // console.log(item)
      // console.log(this.$refs[name])
      if (item !== "BSV") {
        let name = item.symbol;
        item.nameShow = true;
        this.$refs[name][0].style.display = "block";
        this.$refs[name][0].focus();
        this.$forceUpdate();
      } else {
        // console.log(this.$refs[item])
        this.BSVnameShow = true;
        this.$refs[item].style.display = "block";
        this.$refs[item].focus();
        this.$forceUpdate();
      }
    },
    setCurrencyName(item) {
      if (item !== "BSV") {
        item.nameShow = false;
      } else {
        this.BSVnameShow = false;
      }
      this.$utils.gettokenpageData().then((res) => {
        // console.log(res)
        let tokenpageData = res || {};
        tokenpageData.cardName = tokenpageData.cardName
          ? tokenpageData.cardName
          : {};
        if (item !== "BSV") {
          tokenpageData.cardName[item.symbol] = item.cardName;
        } else {
          tokenpageData.cardName.BSV = this.BSVname;
        }
        let params = {
          userType: this.$store.state.userData.phone ? "phone" : "email",
          type: 2,
          tokenString: JSON.stringify(tokenpageData),
        };
        params.userType === "phone"
          ? (params.phone = this.$store.state.userData.phone)
          : (params.email = this.$store.state.userData.email);
        settoken(params)
          .then(() => {
            this.getCardName();
            this.$message({
              message: this.$t("submitSuccess"),
              type: "success",
            });
          })
          .catch((err) => {
            return this.$message.error(this.$t("Error") + err.message);
          });
      });
      this.$forceUpdate();
    },
    getCardName() {
      this.$utils.gettokenpageData().then((res) => {
        let userData = res || {};
        if (userData.cardName) {
          // console.log(userData.cardName, this.myTokenList)
          this.BSVname = userData.cardName.BSV
            ? userData.cardName.BSV
            : "MVC #1";
          this.$store.state.BSVname = this.BSVname;
          for (let i = 0; i < this.myTokenList.length; i++) {
            if (
              userData.cardName[this.myTokenList[i].symbol] ||
              userData.cardName[this.myTokenList[i].symbol] === ""
            ) {
              this.myTokenList[i].cardName =
                userData.cardName[this.myTokenList[i].symbol];
            }
          }
          this.$forceUpdate();
        }
      });
    },
    // 添加钱包
    addWallet() {
      if (this.addWalletDisabled) {
        return this.$message.error(this.$t("discussWarning"));
      } else {
        this.addWalletDisabled = true;
        if (this.wallet_name) {
          for (let i = 0; i < this.tokenizedList.length; i++) {
            // 选中的钱包
            if (
              this.tokenizedList[i].symbol === this.wallet_name &&
              this.walletToken.indexOf(this.wallet_name) === -1
            ) {
              this.walletToken.push(this.tokenizedList[i].symbol);
            }
          }
          // console.log(this.walletToken)
          this.$utils
            .gettokenpageData()
            .then((res) => {
              let tokenpageData = res || {};
              tokenpageData.card = this.walletToken;
              let params = {
                userType: this.$store.state.userData.phone ? "phone" : "email",
                type: 2,
                tokenString: JSON.stringify(tokenpageData),
              };
              params.userType === "phone"
                ? (params.phone = this.$store.state.userData.phone)
                : (params.email = this.$store.state.userData.email);
              settoken(params)
                .then(() => {
                  this.addWalletDisabled = false;
                  this.success = false;
                  this.$utils.getWalletList();
                })
                .catch((err) => {
                  this.addWalletDisabled = false;
                  this.success = false;
                  return this.$message.error(this.$t("Error") + err.message);
                });
            })
            .catch((err) => {
              return this.$message.error(this.$t("Error") + err.message);
            });
        }
      }
    },
    // 点击添加列表时先获取币种列表
    getWalletList() {
      this.$utils
        .gettokenpageData()
        .then((res) => {
          // console.log(res, '币种列表')
          this.walletToken = res?.card ? res?.card : [];
          this.addList = [];
          for (let i = 0; i < this.tokenizedList.length; i++) {
            // 导入所有币种
            this.addList.push(this.tokenizedList[i].symbol);
          }
          for (let j = 0; j < this.walletToken.length; j++) {
            // 删除我已有的
            // console.log(this.addList.indexOf(this.walletToken[j]))
            this.addList.splice(this.addList.indexOf(this.walletToken[j]), 1);
          }
          if (this.addList.length > 0) {
            this.walletRadio = this.addList[0];
          }
          // console.log(this.addList)
        })
        .catch((err) => {
          return this.$message.error(this.$t("Error") + err.message);
        });
    },
    // 选择的钱包
    selectWallet(item) {
      if (this.delShow) {
        item.delShow = !item.delShow;
        this.delButton = this.myTokenList.some((item) => {
          return item.delShow;
        });
        this.$forceUpdate();
      }
      // console.log(item.delShow)
    },
    // 删除钱包
    delWallet() {
      if (this.delWalletDisabled) {
        return this.$message.error(this.$t("discussWarning"));
      } else {
        this.delWalletDisabled = true;
        let list = this.myTokenList.filter((item, index) => {
          if (item.delShow === false) {
            return item.symbol;
          }
        });
        // console.log(list)
        let tokenList = [];
        for (let i = 0; i < list.length; i++) {
          tokenList.push(list[i].symbol);
        }
        this.$utils.gettokenpageData().then((res) => {
          let tokenpageData = res || {};
          tokenpageData.card = tokenList;
          let params = {
            userType: this.$store.state.userData.phone ? "phone" : "email",
            type: 2,
            tokenString: JSON.stringify(tokenpageData),
          };
          params.userType === "phone"
            ? (params.phone = this.$store.state.userData.phone)
            : (params.email = this.$store.state.userData.email);
          settoken(params)
            .then(() => {
              this.delShow = false;
              this.delDialog = false;
              setTimeout(() => {
                this.delWalletDisabled = false;
              }, 500);
              this.$utils.getWalletList();
            })
            .catch((err) => {
              // console.log(err)
              this.delShow = false;
              this.delDialog = false;
              setTimeout(() => {
                this.delWalletDisabled = false;
              }, 500);
              return this.$message.error(this.$t("Error") + err.message);
            });
        });
        // console.log(tokenList)
        // console.log(tokenList)
      }
    },
  },
  watch: {
    "$store.state.myTokenList"() {
      this.myTokenList = this.$store.state.myTokenList;
      for (let i = 0; i < this.myTokenList.length; i++) {
        this.myTokenList[i].delShow = false;
        this.myTokenList[i].nameShow = false;
        this.myTokenList[i].cardName =
          this.myTokenList[i].symbol + this.$t("wallet") + (i + 2);
      }
      this.getCardName();
      this.amountShow = false;
      setTimeout(() => {
        this.amountShow = true;
      }, 500);
    },
    delShow() {
      if (this.delShow === false) {
        this.delButton = false;
        for (let i = 0; i < this.myTokenList.length; i++) {
          this.myTokenList[i].delShow = false;
        }
      }
    },
  },
  async created() {
    await this.$utils.getBalance();
    setTimeout(() => {
      this.amountShow = true;
    }, 500);
    this.tokenizedList =
      this.$store.state.wallet.tokenized.tokenizedList.filter((item) => {
        return item.symbol !== "USD";
      });
    this.getWalletList();
    this.myTokenList = this.$store.state.myTokenList;
    for (let i = 0; i < this.myTokenList.length; i++) {
      this.myTokenList[i].delShow = false;
      this.myTokenList[i].nameShow = false;
      this.myTokenList[i].cardName =
        this.myTokenList[i].symbol + this.$t("wallet") + (i + 2);
    }
    this.getCardName();
  },
};
</script>

<style lang="scss" scoped>
.wallet {
  max-width: 848px;
  width: 100%;
  background: rgba(96, 98, 102, 1);
  border-radius: 23px;
  padding: 25px 35px;
  margin-top: 85px;
  position: relative;
  padding-bottom: 65px;
}
.delType {
  position: absolute;
  bottom: 35px;
  display: flex;
  left: 0;
  padding: 0 35px;
  width: 100%;
  flex-direction: row-reverse;
  button {
    width: 83px;
    height: 30px;
    background: rgba(144, 147, 153, 1);
    border-radius: 15px;
    text-align: center;
    line-height: 30px;
    color: #606266;
  }
  .delButton {
    background: rgba(255, 255, 255, 1);
  }
}
.wallet_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  img {
    width: 33px;
    height: 33px;
    margin-right: 10px;
    vertical-align: middle;
  }
  span {
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
}
.wallet_data {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
}
.wallet_show {
  min-width: 202px;
  margin-top: 10px;
  height: 125px;
  position: relative;
  margin-right: 10px;
  background: linear-gradient(
    -30deg,
    rgba(234, 179, 0, 1),
    rgba(234, 198, 0, 1)
  );
  border-radius: 11px;
}
.del_wallet {
  position: absolute;
  top: -12px;
  left: -10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($color: #fff, $alpha: 0.5);
  span {
    width: 50%;
    height: 50%;
    border-radius: 50%;
  }
  .show {
    background: #fff;
  }
}
.BTC {
  background: linear-gradient(
    -30deg,
    rgba(77, 77, 77, 1),
    rgba(142, 142, 142, 1)
  );
}
.BCH {
  background: linear-gradient(
    -30deg,
    rgba(50, 189, 83, 1),
    rgba(45, 184, 111, 1)
  );
}
.wallet_text {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  // background: url("../../assets/images/ic_BSV@2x.png") no-repeat;
  background-size: 70px 72px;
  color: #fff;
  text-align: right;
  h4 {
    font-size: 16px;
    line-height: 1;
    font-weight: bold;
  }
  p {
    font-size: 16px;
    font-weight: bold;
  }
  span {
    font-size: 14px;
    font-family: "DIN";
    font-weight: bold;
  }
  .currency {
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: space-between;
    > span {
      max-width: 35px;
    }
    > div {
      position: relative;
      height: 100%;
      width: 100%;
      max-width: calc(100% - 45px);
      input {
        position: absolute;
        background: transparent;
        height: 25px;
        max-width: calc(100% - 45px);
        border: none;
        border-bottom: 1px solid #fff;
        right: 0;
        min-width: 50px;
        top: 0;
        width: 100%;
        color: #fff;
        outline: none;
      }
    }
  }
}
// .wallet_add {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   border: 1px dashed #fff;
//   min-width: 202px;
//   height: 125px;
//   border-radius: 11px;
//   margin-right: 10px;
//   margin-top: 10px;
//   img {
//     width: 53px;
//     height: 53px;
//   }
//   span {
//     color: #fff;
//     margin-top: 5px;
//   }
// }
.noK {
  button {
    font-size: 18px;
    color: #fff;
    width: 210px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    display: block;
    margin: 25px auto;
    background: rgba(234, 179, 0, 1);
    border-radius: 11px;
  }
  ::v-deep .el-dialog {
    width: 300px;
    background: rgba(255, 255, 255, 1);
    border-radius: 23px;
  }
  .radio_type {
    height: 60px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
  ::v-deep .el-radio-group {
    width: 100%;
  }
  ::v-deep .el-radio {
    margin-right: 0px;
  }
  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #eab300;
    background: #eab300;
  }
  ::v-deep .el-radio__inner {
    width: 1.74rem;
    height: 1.74rem;
    &::after {
      width: 0.69rem;
      height: 0.69rem;
    }
  }
  ::v-deep .el-radio__label {
    font-weight: normal;
    display: flex;
    align-items: center;
    font-size: 1.67rem;
    color: rgba(48, 49, 51, 1);
  }
}
.centerDialogVisible {
  ::v-deep .el-dialog--center .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog {
    width: 300px;
    border-radius: 25px;
    overflow: hidden;
  }
  ::v-deep .el-dialog__title {
    font-weight: bold;
  }
  .centerDialog {
    padding: 0 2rem;
  }
  .payShow {
    display: block;
    margin: 0 auto;
    padding: 3.78rem;
    color: #303133;
    text-align: center;
    margin-top: 3rem;
    font-size: 1.2rem;
    border-radius: 2.1rem;
  }
  h2 {
    max-height: 25.5rem;
    padding: 0 2rem;
    margin-top: 2rem;
    font-size: 1.3rem;
    font-weight: 500;
    overflow-x: auto;
    color: #909399;
  }
  h2::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 10px;
  }
  h2::-webkit-scrollbar-track {
    box-shadow: none;
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
  }
  h2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    background-color: #aaa;
  }
  .checked {
    padding: 0 2rem;
    margin-top: 2rem;
  }
  .queding {
    height: 5.2rem;
    line-height: 5.2rem;
    text-align: center;
    color: #303133;
    font-size: 1.7rem;
    margin-top: 3rem;
    font-weight: 500;
    border-top: 1px solid #e9e9e9;
    button {
      width: calc(50% - 1px);
      border-right: 1px solid #e9e9e9;
      line-height: 5.2rem;
      text-align: center;
      background: #fff;
      outline: none;
      color: #909399;
      cursor: pointer;
      font-size: 1.7rem;
      &:nth-last-child(1) {
        border-right: none;
      }
    }
    .pay {
      color: #303133;
      font-weight: bold;
    }
    .blue {
      color: rgb(42, 153, 246);
    }
  }
}
@media only screen and (max-width: 1024px) {
  .wallet_show,
  .wallet_add {
    min-width: 45%;
    height: 175px;
  }
}
@media only screen and (max-width: 768px) {
  .wallet {
    padding: 25px 15px;
  }
  .wallet_show,
  .wallet_add {
    width: 100%;
  }
}
</style>
