<template>
  <div class="avatarDialog">
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogShow"
      class="dialogWrap"
      :show-close="false"
      center
      :append-to-body="true"
      @close="dialogClose"
    >
      <div class="top">
        <i class="el-icon-arrow-left" @click.stop="dialogClose"></i>
        <span>{{ $t("switchAvatar") }}</span>
        <div class="confirme" @click.stop="confirmeUpload">
          {{ $t("confirm") }}
        </div>
      </div>
      <div class="bigAvatar">
        <!-- <img :src="uploadFile ? uploadFile : $options.filters.replaceUrl(confirmeAvatar)" alt=""> -->
        <img :src="confirmeAvatar | replaceUrl" alt="" />
      </div>
      <div class="tabBar">
        <van-tabs v-model="activeName">
          <div class="tips">
            {{ activeName == "custom" ? $t("avatarDesc1") : $t("avatarDesc2") }}
          </div>
          <van-tab :title="$t('avatarTitle1')" name="custom" class="tabList">
            <div class="avatarWrap">
              <div class="avatarItem">
                <!-- <img :src="uploadFile ? uploadFile : $options.filters.replaceUrl(getCustomAvatar)" alt="" class="avatarImg" @click.stop='selectedCustom'> -->
                <img
                  :src="getCustomAvatar | replaceUrl"
                  alt=""
                  class="avatarImg"
                  @click.stop="selectedCustom"
                />
                <img
                  :src="selectIcon"
                  alt=""
                  class="selectIcon"
                  v-show="customShow"
                />
              </div>
            </div>
          </van-tab>
          <van-tab :title="$t('avatarTitle2')" name="nft" class="tabList">
            <div class="avatarWrap" v-if="metaBotAvatar.length">
              <div
                class="avatarItem"
                v-for="(item, index) in metaBotAvatar"
                :key="index"
              >
                <img
                  :src="item.avatarTxId | replaceUrl"
                  alt=""
                  class="avatarImg"
                  @click.stop="selected(item, index)"
                />
                <img
                  :src="selectIcon"
                  alt=""
                  class="selectIcon"
                  v-show="select == index"
                />
              </div>
            </div>
            <div class="emptyNft" v-else>
              <img src="../assets/images/blankNFT.png" alt="" />
              <span>{{ $t("noNftAvatar") }}</span>
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <div class="upload">
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeAvatarUpload"
          :show-file-list="false"
          :auto-upload="false"
          action="#"
          :on-change="changeUpload"
          accept="image/jpeg,image/png,image/webp"
        >
          <div class="btn" v-if="activeName == 'custom'">
            <van-button type="primary"> {{ $t("uploadAvatar") }} </van-button>
          </div>
        </el-upload>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getNftAvatar } from "@/api/api";
import { avatarUrl, imageUrl } from "../common/js/baseApi";

export default {
  name: "Dialog",
  props: {
    dialogShow: {
      type: Boolean,
      default: false,
    },
    option: {
      type: String,
      default: "",
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    mataImg: {
      type: Boolean,
      default: false,
    },
    customAvatar: {
      type: String,
      default: "",
    },
    uploadFile: {
      type: String,
      default: "",
    },
    imageUrl: {
      type: String,
      default: "",
    },
    nftAvatar: {
      type: String,
      default: "",
    },
    customTxAvatar: {
      type: String,
      default: "",
    },
    selectedNftInfo: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {
      activeName: "custom",
      isSelected: false,
      selectIcon: require("../assets/images/selectIcon.png"),
      avatarId: null,
      customShow: false,
      fileinfo: {},
      metaBotAvatar: [],
    };
  },
  filters: {
    replaceUrl(value) {
      // if (!value) return "";
      if (!value) return `${avatarUrl()}undefined`;
      if (value.indexOf("data:") != -1) return value;
      if (value.indexOf("metafile://") != -1) {
        return (
          imageUrl() + value.replace("metafile://", "compress/").toLowerCase()
        );
      } else {
        return `${imageUrl()}${value}`;
      }
    },
  },
  watch: {
    dialogShow: {
      handler(val) {
        if (val == false) {
          // this.uploadFile=''
          this.$emit("update:uploadFile", "");
          this.$emit("update:nftAvatar", "");
          this.$emit("update:customAvatar", "");
        }
      },
    },
  },
  computed: {
    confirmeAvatar() {
      if (this.userMeta.avatarType === "nft-metabot") {
        return this.userMeta.avatarTxId;
      }
      if (!this.customShow && !this.option) {
        return this.getMetaBotAvatar;
      } else if (!this.option) {
        return this.userMeta.customizeAvatarTxId;
      } else {
        return this.option;
      }
    },
    getCustomAvatar() {
      console.log("zzzzzzzzzzzzzzz", this.customAvatar);
      console.log("zxassasdasdasdas", this.userMeta.customizeAvatarTxId);
      // if(this.customAvatar){
      //     return this.customAvatar
      // }else{
      //     return this.userMeta.customizeAvatarTxId
      // }
      return this.userMeta.customizeAvatarTxId;
    },
    getMetaBotAvatar() {
      if (this.nftAvatar) {
        return this.nftAvatar;
      } else {
        return this.userMeta.customizeAvatarTxId;
      }
    },
    select() {
      return this.avatarId;
    },

    ...mapState(["nftBotAvatar", "userData", "userMeta"]),
  },

  methods: {
    confirmeUpload() {
      console.log("aaaasasdasdasdas", this.nftAvatar);
      this.$emit("update:mataImg", true);
    },
    selectedCustom() {
      console.log(
        this.option,
        this.avatarId,
        this.customShow,
        this.userMeta.customizeAvatarTxId
      );
      this.$emit("update:nftAvatar", "");
      this.$emit("update:option", "");
      this.$emit("update:customTxAvatar", this.userMeta.customizeAvatarTxId);
      this.customShow = true;
      this.avatarId = null;
    },
    selected(item, id) {
      this.$emit("update:option", "");
      console.log("fucksasa", item);
      this.$emit("update:selectedNftInfo", item);
      this.$emit("update:customTxAvatar", "");
      this.customShow = false;
      this.$emit("update:nftAvatar", item.avatarTxId);
      // this.nftAvatar=item.avatarTxId
      this.avatarId = id;
    },
    dialogClose() {
      this.$emit("update:dialogShow", false);
    },
    // 上传头像
    changeUpload(file, fileList) {
      console.log("asssssssssssssss", file);
      this.fileinfo = file;

      const reader = new FileReader();
      //   var _this = this;
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        console.log("99999999", data);
        // this.uploadFile=data
        // this.customAvatar=data
        this.customShow = false;
        // this.option.img = data;
        this.$emit("update:customTxAvatar", "");
        this.$emit("update:nftAvatar", "");
        this.$emit("update:option", data);
        // console.log("99998889999",this.option)
        // this.dialogVisible = true;
        this.$emit("update:dialogVisible", true);
      };
      reader.readAsDataURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error(this.$t("uploadingExplain"));
        return false;
      }
      return isLt5M;
    },
    getNftAvaList() {
      getNftAvatar({
        page: "1",
        pageSize: "999",
        address: this.$store.state.metanet.rootAddress.address,
      })
        .then((res) => {
          console.log("axzasasdas", res);
          if (res.data.code == 0) {
            this.metaBotAvatar = res.data.data.results.items;
          } else {
            this.metaBotAvatar = [];
          }
          this.$store.dispatch("setMetaBot", this.metaBotAvatar);
        })
        .catch((e) => {
          this.$message.error("获取MetaBot头像失败");
        });
    },
  },
  created() {
    this.getNftAvaList();
  },
  mounted() {
    console.log("6666666", this.userMeta, this.getCustomAvatar);
    // console.log("wwewawad",this.metaBotAvatar)
    // console.log("wwessswawad",this.userData)
  },
};
</script>

<style lang="scss" scoped>
.dialogWrap {
  ::v-deep .el-dialog {
    width: 40vw !important;
    border-radius: 1.666667rem /* 20/12 */;
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    i {
      font-size: 1.333333rem /* 16/12 */;
      color: #525355;
      font-weight: bold;
      cursor: pointer;
    }
    span {
      margin-left: 2.083333rem /* 25/12 */;
      font-size: 1.333333rem /* 16/12 */;
      color: #303133;
      font-weight: bold;
    }
    .confirme {
      font-size: 1.166667rem /* 14/12 */;
      padding: 0.333333rem /* 4/12 */ 0.666667rem /* 8/12 */;
      background-color: #feb338;
      color: #fff;
      border-radius: 0.166667rem /* 2/12 */;
      cursor: pointer;
    }
  }
  .bigAvatar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.416667rem /* 17/12 */;
    margin-bottom: 2.583333rem /* 31/12 */;
    img {
      width: 9.166667rem /* 110/12 */;
      height: 9.166667rem /* 110/12 */;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .tabBar {
    //    height: 60%;
    margin-bottom: 8.333333rem /* 100/12 */;
    .tips {
      color: #b0b3b7;
      padding-left: 1.25rem /* 15/12 */;
      padding-top: 0.583333rem /* 7/12 */;
      margin-bottom: 1.666667rem /* 20/12 */;
    }
    ::v-deep .van-tabs__wrap {
      .van-tabs__line {
        width: 50% !important;
        background-color: #feb338;
      }
      .van-tab--active {
        color: #feb338 !important;
      }
      .van-tab__text {
        font-weight: bold;
        font-size: 1.333333rem /* 16/12 */;
      }
    }
    .tabList {
      //  overflow-y: scroll;
      .emptyNft {
        padding-top: 10rem /* 120/12 */;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          width: 10rem /* 120/12 */;
          height: 10rem /* 120/12 */;
        }
        span {
          margin-top: 2.083333rem /* 25/12 */;
          color: #c1c3c6;
          font-weight: bold;
          font-size: 1.666667rem /* 20/12 */;
        }
      }
      .avatarWrap {
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
          background-color: #606266;
          border-radius: 10px;
          display: block;
        }
        &::-webkit-scrollbar-track {
          box-shadow: block;
          /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
          border-radius: 10px;
          background-color: rgba(255, 255, 255, 0.1);
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
          background-color: #aaa;
        }
        height: 25rem /* 300/12 */;
        overflow: auto;

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        .avatarItem {
          margin-right: 2.916667rem /* 35/12 */;
          margin-top: 2.5rem /* 30/12 */;
          position: relative;
          .avatarImg {
            width: 9.166667rem /* 110/12 */;
            height: 9.166667rem /* 110/12 */;
            object-fit: cover;
            border-radius: 50%;
          }
          .selectIcon {
            position: absolute;
            width: 3.333333rem /* 40/12 */;
            height: 3.333333rem /* 40/12 */;
            border-radius: 50%;
            bottom: -0.833333rem /* 10/12 */;
            left: 30%;
          }
        }
        .avatarItem:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }
  .upload {
    .avatar-uploader {
      ::v-deep .el-upload {
        width: 100%;
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      .van-button {
        width: 80%;
        padding: 2rem /* 24/12 */;
        background-color: #feb338;
        border-radius: 0.833333rem /* 10/12 */;
        font-size: 1.5rem /* 18/12 */;
        font-weight: bold;
      }
      .van-button--primary {
        border: 1px solid #feb338;
      }
    }
  }
}

::v-deep .el-dialog__header {
  display: none;
}
@media screen and (max-width: 1360px) and (min-width: 768px) {
  .dialogWrap {
    ::v-deep .el-dialog {
      width: 70vw !important;
    }
  }
}
@media screen and (max-width: 768px) {
  .dialogWrap {
    ::v-deep .el-dialog {
      width: 90vw !important;
    }
  }
}
@media screen and (max-width: 500px) {
  .dialogWrap {
    .bigAvatar {
      margin-top: 1.416667rem /* 17/12 */;
      margin-bottom: 2.583333rem /* 31/12 */;
      img {
        width: 6.666667rem /* 80/12 */;
        height: 6.666667rem /* 80/12 */;
      }
    }
    .tabBar {
      //    height: 60%;
      margin-bottom: 6.666667rem /* 80/12 */;
      .tabList {
        .emptyNft {
          padding-top: 10rem /* 120/12 */;
          img {
            width: 6.666667rem /* 80/12 */;
            height: 6.666667rem /* 80/12 */;
          }
        }
        .avatarWrap {
          height: 16.666667rem /* 200/12 */;
          .avatarItem {
            margin-right: 2.083333rem /* 25/12 */;
            margin-top: 1.666667rem /* 20/12 */;
            position: relative;
            .avatarImg {
              width: 6.666667rem /* 80/12 */;
              height: 6.666667rem /* 80/12 */;
              object-fit: cover;
              border-radius: 50%;
            }
            .selectIcon {
              position: absolute;
              width: 2.083333rem /* 25/12 */;
              height: 2.083333rem /* 25/12 */;
              border-radius: 50%;
              bottom: -0.416667rem /* 5/12 */;
              left: 30%;
            }
          }
          .avatarItem:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
