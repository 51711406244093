var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "avatarDialog" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialogWrap",
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.dialogShow,
            "show-close": false,
            center: "",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogShow = $event
            },
            close: _vm.dialogClose,
          },
        },
        [
          _c("div", { staticClass: "top" }, [
            _c("i", {
              staticClass: "el-icon-arrow-left",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.dialogClose.apply(null, arguments)
                },
              },
            }),
            _c("span", [_vm._v(_vm._s(_vm.$t("switchAvatar")))]),
            _c(
              "div",
              {
                staticClass: "confirme",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.confirmeUpload.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("confirm")) + "\n      ")]
            ),
          ]),
          _c("div", { staticClass: "bigAvatar" }, [
            _c("img", {
              attrs: { src: _vm._f("replaceUrl")(_vm.confirmeAvatar), alt: "" },
            }),
          ]),
          _c(
            "div",
            { staticClass: "tabBar" },
            [
              _c(
                "van-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("div", { staticClass: "tips" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.activeName == "custom"
                            ? _vm.$t("avatarDesc1")
                            : _vm.$t("avatarDesc2")
                        ) +
                        "\n        "
                    ),
                  ]),
                  _c(
                    "van-tab",
                    {
                      staticClass: "tabList",
                      attrs: { title: _vm.$t("avatarTitle1"), name: "custom" },
                    },
                    [
                      _c("div", { staticClass: "avatarWrap" }, [
                        _c("div", { staticClass: "avatarItem" }, [
                          _c("img", {
                            staticClass: "avatarImg",
                            attrs: {
                              src: _vm._f("replaceUrl")(_vm.getCustomAvatar),
                              alt: "",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.selectedCustom.apply(null, arguments)
                              },
                            },
                          }),
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.customShow,
                                expression: "customShow",
                              },
                            ],
                            staticClass: "selectIcon",
                            attrs: { src: _vm.selectIcon, alt: "" },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "van-tab",
                    {
                      staticClass: "tabList",
                      attrs: { title: _vm.$t("avatarTitle2"), name: "nft" },
                    },
                    [
                      _vm.metaBotAvatar.length
                        ? _c(
                            "div",
                            { staticClass: "avatarWrap" },
                            _vm._l(_vm.metaBotAvatar, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "avatarItem" },
                                [
                                  _c("img", {
                                    staticClass: "avatarImg",
                                    attrs: {
                                      src: _vm._f("replaceUrl")(
                                        item.avatarTxId
                                      ),
                                      alt: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.selected(item, index)
                                      },
                                    },
                                  }),
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.select == index,
                                        expression: "select == index",
                                      },
                                    ],
                                    staticClass: "selectIcon",
                                    attrs: { src: _vm.selectIcon, alt: "" },
                                  }),
                                ]
                              )
                            }),
                            0
                          )
                        : _c("div", { staticClass: "emptyNft" }, [
                            _c("img", {
                              attrs: {
                                src: require("../assets/images/blankNFT.png"),
                                alt: "",
                              },
                            }),
                            _c("span", [_vm._v(_vm._s(_vm.$t("noNftAvatar")))]),
                          ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "upload" },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    "before-upload": _vm.beforeAvatarUpload,
                    "show-file-list": false,
                    "auto-upload": false,
                    action: "#",
                    "on-change": _vm.changeUpload,
                    accept: "image/jpeg,image/png,image/webp",
                  },
                },
                [
                  _vm.activeName == "custom"
                    ? _c(
                        "div",
                        { staticClass: "btn" },
                        [
                          _c("van-button", { attrs: { type: "primary" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("uploadAvatar")) + " "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }